.main_select{
    position: relative;
    margin-bottom: 10px;
}
.main_select .result{
    width: 100%;
    border: 1px solid #ced4da;
    padding-right: 20px;
    border-radius: 0.25rem;
    color: #495057;
    cursor: pointer;
    line-height: 38px;
    background-color: white;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.main_select input{
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    margin: 0 auto;
}
.main_select input:focus{
    border: 1px solid #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    outline: none !important;

}
.main_select .search_bar{
    width: 100%;
    padding: 5px;
}

.main_select .list{
    width: 100%;
    background: white;
    border: 1px solid #ced4da;
    border-radius:   0.25rem ;
    display: none;
    position: absolute;
    z-index: 1;
}
.main_select .arrow{
    cursor: pointer;
    border-left: 2px solid #495057;
    border-bottom: 2px solid #495057;
    position: absolute;
    transform: rotate(-45deg);
    top: 13px;
    right: 7px;
    width: 8px;
    height: 8px;
}
.main_select .arrow.active_arrow{
    transform: rotate(135deg);
    top: 16px;
}
.main_select .list ul{
    margin-top: 3px;
    padding: 0;
    overflow: auto;
    max-height: 120px;
}

.main_select .list.active_select{
    display: block;
}
.main_select ul li{
    width: 100%;
    list-style-type: none;
    line-height: 40px;
    cursor: pointer;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.main_select ul li:hover{
    color: #4361ee;
}
.main_select ul li.active_select_li{
    color: #4361ee;
    background-color: rgba(27, 85, 226, 0.239216);
}

@media (min-width: 1200px) and (max-width: 1371px) {
    .phone-inline {
        height: 50px;
    }

}
